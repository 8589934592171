import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import { nextAppEnv } from "@Utils/environment";
import i18nextConfig from "@wff/next-i18next.config";
import { localeSelector } from "store/localeSlice";

export const useRedirect = (to?: string) => {
  const locale = useSelector(localeSelector);
  const router = useRouter();
  to = to || router.route;

  const { locales, defaultLocale } = i18nextConfig.i18n;
  const languageCode = locale?.languageCode;
  locales.splice(locales.indexOf(defaultLocale), 1);
  locales.push(defaultLocale);

  // language detection
  useEffect(() => {
    router.replace(
      "/" + (languageCode ?? nextAppEnv.application.locale ?? "en-US") + to
    );
  }, [router, to, languageCode]);

  return <></>;
};

export const Redirect = () => {
  useRedirect();
  return <></>;
};

// eslint-disable-next-line react/display-name
export const getRedirect = (to: string) => () => {
  useRedirect(to);
  return <></>;
};
